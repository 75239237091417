var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',{staticClass:"grid grid-cols-1 lg:grid-cols-4 gap-4"},[_c('Card',{attrs:{"title":"Personal","icon":"stats-1 text-5xl","stats":_vm.data.personal && _vm.data.personal.all_personal_count,"message":_vm.data.personal && _vm.data.personal.message,"url":"/statistic/personal/","error":_vm.data.personal && _vm.data.personal.loss ? true : false}}),_c('Card',{attrs:{"title":"Abteilungen","icon":"stats-2 text-5xl","stats":_vm.data.unit && _vm.data.unit.all_count,"message":_vm.data.unit && _vm.data.unit.message,"url":"/statistic/unit/"}}),_c('Card',{attrs:{"title":"Besetzte Rollenprofile","icon":"stats-3 text-5xl","stats":_vm.data.roleprofile && _vm.data.roleprofile.ratio,"message":_vm.data.roleprofile && _vm.data.roleprofile.message,"url":"/statistic/roleprofile/","error":_vm.data.roleprofile &&
          _vm.data.roleprofile.unoccupied_roleprofiles &&
          _vm.data.roleprofile &&
          _vm.data.roleprofile.unoccupied_roleprofiles.length,"hover":_vm.data.roleprofile &&
          _vm._.join(
            _vm._.map(_vm.data.roleprofile.unoccupied_roleprofiles, 'name'),
            '<br />'
          )}}),_c('Card',{attrs:{"title":"Offene Gespräche","icon":"stats-4 text-5xl","stats":_vm.data['performance-evaluation']
          ? _vm.data['performance-evaluation'].ratio
          : null,"message":_vm.data['performance-evaluation']
          ? _vm.data['performance-evaluation'].message
          : null,"url":"/statistic/performance-evaluation/","error":true}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('Graph',{attrs:{"title":"Leistungsbeurteilungen","event":"evaluationEvent","filter":_vm.filter,"data":Object.assign({}, _vm.evaluationChart,
        {options:
          _vm.evaluationChart.options && _vm.evaluationChart.options.chartOptions
            ? _vm.evaluationChart.options.chartOptions
            : {}})},on:{"evaluationEvent":_vm.initEvaluationGraph}}),_c('Graph',{attrs:{"title":"Mitarbeiter Zufriedenheit","event":"satisfactionEvent","filter":_vm.filter,"data":Object.assign({}, _vm.satisfactionChart,
        {options:
          _vm.satisfactionChart.options && _vm.satisfactionChart.options.chartOptions
            ? _vm.satisfactionChart.options.chartOptions
            : {}})},on:{"satisfactionEvent":_vm.initSatisfactionGraph}})],1),_c('div',{staticClass:"flex-wrap justify-between"},[_c('div',{staticClass:"grid grid-cols-1 mt-4"},[_c('data-table',{attrs:{"heading_title":"Personalentwicklungsmassnahmen","value":_vm.developmentActions,"show-calendar":true,"show-filters":false,"show-status":true},on:{"loaded":function($event){_vm.loadNext = true}}})],1),_c('div',{staticClass:"flex space-x-4 mt-4"},[_c('div',{staticClass:"w-1/3"},[_c('Graph',{attrs:{"hide":true,"data":_vm.feedbackChart,"show-calendar":true},on:{"year":_vm.initGraph}})],1),(_vm.loadNext)?_c('div',{staticClass:"w-2/3"},[_c('data-table',{attrs:{"heading_title":"Feedback","value":_vm.feedbacks,"show-calendar":true,"show-filters":false,"show-category":true,"hide-category":true}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }