<template>
  <div class=" flex flex-col">
    <div class="wizard">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <nav aria-label="Progress">
        <ol class="border border-gray-300 rounded divide-y divide-gray-300 md:flex md:divide-y-0">
          <li
            class="relative md:flex-1 md:flex"
            v-for="(step, index) in wizard.steps"
            :key="`step-${index}`"
          >
            <!-- Completed Step -->
            <a
              v-if="wizard.currentStep > index + 1"
              href="#"
              class="group flex items-center w-full"
            >
              <span class="p-2 flex items-center text-xs font-medium">
                <span
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
                >
                  <!-- Heroicon name: solid/check -->
                  <svg
                    class="w-6 h-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span class="ml-4 text-xs font-medium text-gray-900">{{ step.title }}</span>
              </span>
            </a>

            <a
              href="#"
              class="p-2 flex items-center text-xs font-medium"
              aria-current="step"
              v-else
            >
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full"
              >
                <span class="text-indigo-600">0{{ index + 1 }}</span>
              </span>
              <span class="ml-4 text-xs font-medium text-indigo-600">{{ step.title }}</span>
            </a>

            <!-- Arrow separator for lg screens and up -->
            <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ol>
      </nav>

      <div class="pt-8">
        <div v-show="wizard.currentStep == 1" class="flex">
          <div class="w-full">
            <div class="space-y-3 flex flex-col">
              <div class="">
                <label for="first_name" class="block text-sm font-medium text-gray-700">
                  Geburstdatum
                </label>
                <date-picker
                  v-model="$v.personal.birth_date.$model"
                  :input-class="
                    `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent ${$v
                      .personal.birth_date.$error && 'border-red-500'}`
                  "
                  placeholder="Geburstdatum"
                />
              </div>

              <div class="sm:col-span-3">
                <label for="last_name" class="block text-sm font-medium text-gray-700">
                  Eintrittsdatum
                </label>
                <date-picker
                  v-model="$v.personal.start_date.$model"
                  :input-class="
                    `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent  ${$v
                      .personal.start_date.$error && 'border-red-500'}`
                  "
                  placeholder="Eintrittsdatum"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col space-y-4" v-show="wizard.currentStep == 2">
          <div class="w-full">
            <input
              type="text"
              v-model="company.address"
              placeholder="Adresse"
              class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
            />
          </div>

          <div class="flex space-x-2">
            <div class="w-1/4">
              <input
                type="text"
                v-model="company.postcode"
                placeholder="Postleitzahl"
                class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
            <div class="w-3/4">
              <input
                type="text"
                v-model="company.place"
                placeholder="Ort"
                class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
          </div>
          <div class="w-full">
            <div class="w-full">
              <input
                type="text"
                v-model="company.phone"
                placeholder="Telefon"
                class="border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
              />
            </div>
          </div>
          <div class="w-full">
            <form-select v-model="company.legal_form" :options="options" placeholder="Rechtsform" />
          </div>
        </div>

        <div class="flex flex-col space-y-4" v-show="wizard.currentStep >= 3">
          <div class="sm:col-span-6 relative">
            <div
              class="mt-1 flex justify-center px-6 py-3 border-2 border-gray-300 border-dashed rounded"
            >
              <div class="space-y-1 w-48 h-64 text-center">
                <div
                  v-if="company.logo && company.logo.url"
                  class="w-full h-full bg-contain bg-no-repeat bg-center"
                  :style="`background-image: url(${company.logo.url})`"
                ></div>
                <div v-else>
                  <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div class="flex text-sm text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer bg-white rounded font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>Firmenlogo hochladen</span>
                    </label>
                    <!-- <p class="pl-1">or drag and drop</p> -->
                  </div>
                  <p class="text-xs text-gray-500">
                    PNG, JPG, GIF bis zu 10MB
                  </p>
                </div>
              </div>
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              class="w-full absolute inset-0 opacity-0"
              @change="e => (company.logo = onFileChange(e))"
            />
          </div>
        </div>
      </div>

      <div class="mt-5 sm:mt-6 flex justify-between">
        <a
          href="#"
          @click.prevent="updateCurrent(-1)"
          class="inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          v-if="wizard.currentStep > 1"
          >Zurück</a
        >
        <template v-if="wizard.currentStep < wizard.steps.length">
          <span class="w-px"></span>
          <a
            href="#"
            @click.prevent="updateCurrent(1)"
            class="mt-3 inline-flex justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            >Weiter</a
          >
        </template>
        <a
          href="#"
          @click.prevent="save"
          class="mt-3 inline-flex justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          v-else
          >Speichern</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
// import { createOne, me } from '@/services/personal'
import { createOne } from '@/services/personal'
import { updateOrg } from '@/services/organization'

export default {
  name: 'first-time-login',
  components: {},
  data() {
    return {
      personal: {
        first_name: null,
        last_name: null,
        email: null,
        birth_date: null,
        start_date: null,
      },
      company: {
        address: null,
        postcode: null,
        place: null,
        phone: null,
        legal_form: '',
        logo: null,
        update_first_time_login: true,
      },
      options: [
        { label: 'Einzelfirma', value: 'Einzelfirma' },
        { label: 'Personengesellschaft', value: 'Personengesellschaft' },
        { label: 'Stiftung', value: 'Stiftung' },
        { label: 'Aktiengesellschaft (AG)', value: 'Aktiengesellschaft (AG)' },
        {
          label: 'Gesellschaft mit beschränkter Haftung (GmbH)',
          value: 'Gesellschaft mit beschränkter Haftung (GmbH)',
        },
        { label: 'Verein', value: 'Verein' },
      ],
      wizard: {
        currentStep: 1,
        steps: [
          {
            id: 1,
            title: 'Erfassen Sie Ihre Personaldaten',
          },
          {
            id: 2,
            title: 'Erfassen Sie Ihre Firmenstammdaten',
          },
          {
            id: 3,
            title: 'Firmenlogo',
          },
        ],
      },
    }
  },
  validations() {
    return {
      personal: {
        birth_date: {
          required,
        },
        start_date: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.company = {
        ...this.company,
        ...this.profile,
        update_first_time_login: true,
      }
    },
    save() {
      this.personal.first_name = this.profile.first_name
      this.personal.last_name = this.profile.last_name
      this.personal.email = this.profile.email
      this.company.logo = this.company && this.company.logo && this.company.logo.file
      this.personal.user = this.profile.id

      createOne({ ...this.personal })
        .then(response => {
          if (response.success) this.wizard.currentStep++

          this.updateProfile({
            ...this.profile,
            ...response.data,
          })

          if (this.company.logo) {
            updateOrg(this.company).then(orgResponse => {
              this.updateProfile({
                ...this.profile,
                org: { ...orgResponse.data.data },
              })

              this.$emit('close-modal', { show: false })
            })
          } else {
            this.updateProfile({
              ...this.profile,
              org: {
                ...this.profile.org,
                update_first_time_login: true,
              },
            })
            this.$emit('close-modal', { show: false })
          }
        })
        .catch(err => {
          console.log('Err ::: ', err)
          this.$emit('close-modal', { show: false })
        })
    },
    updateCurrent(step) {
      this.$v.personal.$touch()

      const newValue = {
        currentStep: this.wizard.currentStep + step,
        steps: this.wizard.steps,
      }

      if (!this.$v.$invalid) this.wizard = newValue
    },
  },
  watch: {
    create: function(n) {
      if (!n) this.wizard.currentStep = 1
    },
  },
}
</script>

<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
