<template>
  <div class="w-full flex flex-col">
    
    <div class="flex justify-between items-center mb-2">
      <div class="w-1/2 text-base mr-1 font-medium">{{ title }}</div>
      <div class="w-1/2 flex justify-end">
              
          <v-select 
          v-if="filter"
          @input="onChange"
          :options="filter"
          v-model="filteroption"
          :clearable="true"
          placeholder="Hierarchie-Ebene auswählen"
          class="style-chooser mb-2 border border-none shadow rounded-md p-1 text-xs focus:outline-none w-full bg-white"
          />
        <div v-if="rating" class="text-base font-bold">
          Gesamt Zufriedenheit:
          <span
            :class="
              rating >= 7
                ? 'text-green-500'
                : rating >= 4 && rating < 7
                ? 'text-yellow-500'
                : 'text-red-500'
            "
            v-html="rating"
          ></span>
        </div>
        <div>
          <date-picker
            input-class="shadow p-2 border-none rounded-md bg-white"
            v-if="showCalendar"
            type="year"
            v-model="year"
            format="YYYY"
            value-type="format"
            :default-value="year"
            :clearable="false"
            :editable="false"
          />
        </div>
      </div>
      <!-- <date-filter v-if="!hide" v-model="between" /> -->
    </div>
    <template v-if="!data.error">
      <template v-if="options && options.chart">
        <div class="bg-white p-4 shadow rounded-md">
        <apexchart
          :type="chartType"
          :options="options"
          :series="series"
          :height="options.chart.height"
        ></apexchart>
        </div>

      </template>
    </template>
    <template v-else>
      <div class="flex flex-col w-full h-full items-center justify-center">
        <p class="font-bold text-sm">{{ data.error }}</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'graph',
  props: ['title', 'data', 'rating', 'hide', 'cssClass', 'showCalendar', 'filter', 'event'],
  data() {
    return {
      filteroption: null,
      between: [],
      year: null,
    }
  },
  mounted() {
    this.year = this.moment().format('YYYY')

  },
  methods: {
    onChange(){
      this.$emit(this.event, this.filteroption);
    }
  },
  computed: {
    chartType() {
      return this.data ? this.data.chartType : {}
    },
    options() {
      return this.data
        ? this.data.options
        : {
            chart: {},
          }
    },
    series() {
      return this.data ? this.data.series : []
    },
  },
  watch: {
    year: function(n) {
      this.$emit('year', n)
    },
  },
}
</script>
