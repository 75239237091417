<template>
  <data-table v-model="data" :columns="columns" :show-filters="false" />
  <!-- <div class="flex flex-col rounded bg-white p-4 shadow overflow-x-auto">
    <div class="mb-2">
      <div class="text-lg">{{ title }}</div>
    </div>

    <div class="flex flex-col">
      <div class="align-middle inline-block w-full">
        <table class="min-w-full divide-y divide-gray-200 overflow-y-auto">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                v-for="(column, index) in columns"
                :key="`column-${index}`"
              >
                {{ column.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="r_index % 2 ? 'bg-gray-50' : 'bg-white'"
              v-for="(data, r_index) in data"
              :key="`row-${r_index}`"
            >
              <td
                class="px-6 py-4 whitespace-nowrap text-xs font-normal text-gray-900"
                v-for="(column, c_index) in columns"
                :key="`value-${r_index}-${c_index}`"
              >
                <template v-for="key in column.key">
                  <template v-if="key === 'status'">
                    {{ statusTranslate(_.get(data, key)) }}
                  </template>
                  <template v-else>
                    {{ column.prefix }} {{ column.callback(_.get(data, key)) }}
                  </template>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'stats',
  props: ['title', 'data', 'columns'],
  data() {
    return {
      between: [],
    }
  },
}
</script>

<style></style>
