<template>
  <div class="flex flex-col space-y-4">
    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <Card
        title="Personal"
        icon="stats-1 text-5xl"
        :stats="data.personal && data.personal.all_personal_count"
        :message="data.personal && data.personal.message"
        url="/statistic/personal/"
        :error="data.personal && data.personal.loss ? true : false"
      />
      <Card
        title="Abteilungen"
        icon="stats-2 text-5xl"
        :stats="data.unit && data.unit.all_count"
        :message="data.unit && data.unit.message"
        url="/statistic/unit/"
      />
      <Card
        title="Besetzte Rollenprofile"
        icon="stats-3 text-5xl"
        :stats="data.roleprofile && data.roleprofile.ratio"
        :message="data.roleprofile && data.roleprofile.message"
        url="/statistic/roleprofile/"
        :error="
          data.roleprofile &&
            data.roleprofile.unoccupied_roleprofiles &&
            data.roleprofile &&
            data.roleprofile.unoccupied_roleprofiles.length
        "
        :hover="
          data.roleprofile &&
            _.join(
              _.map(data.roleprofile.unoccupied_roleprofiles, 'name'),
              '<br />'
            )
        "
      />
      <Card
        title="Offene Gespräche"
        icon="stats-4 text-5xl"
        :stats="
          data['performance-evaluation']
            ? data['performance-evaluation'].ratio
            : null
        "
        :message="
          data['performance-evaluation']
            ? data['performance-evaluation'].message
            : null
        "
        url="/statistic/performance-evaluation/"
        :error="true"
      />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <Graph
        title="Leistungsbeurteilungen"
        @evaluationEvent="initEvaluationGraph"
        event="evaluationEvent"
        :filter="filter"
        :data="{
          ...evaluationChart,
          options:
            evaluationChart.options && evaluationChart.options.chartOptions
              ? evaluationChart.options.chartOptions
              : {},
        }"
      />
      <Graph
        title="Mitarbeiter Zufriedenheit"
        @satisfactionEvent="initSatisfactionGraph"
        event="satisfactionEvent"
        :filter="filter"
        :data="{
          ...satisfactionChart,
          options:
            satisfactionChart.options && satisfactionChart.options.chartOptions
              ? satisfactionChart.options.chartOptions
              : {},
        }"
      />
    </div>

    <div class="flex-wrap justify-between">
      <div class="grid grid-cols-1 mt-4">
        <data-table
          heading_title="Personalentwicklungsmassnahmen"
          :value="developmentActions"
          :show-calendar="true"
          :show-filters="false"
          :show-status="true"
          @loaded="loadNext = true"
        />
      </div>
      <div class="flex space-x-4 mt-4">
        <div class="w-1/3">
          <Graph
            :hide="true"
            :data="feedbackChart"
            :show-calendar="true"
            @year="initGraph"
          />
        </div>
        <div v-if="loadNext" class="w-2/3">
          <data-table
            heading_title="Feedback"
            :value="feedbacks"
            :show-calendar="true"
            :show-filters="false"
            :show-category="true"
            :hide-category="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/utils/axios'
import { Card, Graph } from '@/components/Stats'

import FirstTimeLogin from '@/components/Forms/Organisation/FirstTimeLogin'

const colors = [
  '#0000A0',
  '#409CA3',
  '#005F89',
  '#00B000',
  '#77BDD5',
  '#FFD342',
  '#273F87',
  '#6F7CEE',
  '#4FB494',
  '#0093B7',
  '#A0C5CF',
  '#357382',
  '#8D86F0',
  '#7CC5C6',
  '#D8954E',
  '#756E66',
  '#944972',
  '#0000A0',
  '#409CA3',
  '#005F89',
  '#00B000',
  '#77BDD5',
  '#FFD342',
  '#273F87',
  '#6F7CEE',
  '#4FB494',
  '#0093B7',
  '#A0C5CF',
  '#357382',
  '#8D86F0',
  '#7CC5C6',
  '#D8954E',
  '#756E66',
  '#944972',
  '#0000A0',
  '#409CA3',
  '#005F89',
  '#00B000',
  '#77BDD5',
  '#FFD342',
  '#273F87',
  '#6F7CEE',
  '#4FB494',
  '#0093B7',
  '#A0C5CF',
  '#357382',
  '#8D86F0',
  '#7CC5C6',
  '#D8954E',
  '#756E66',
  '#944972',
]

export default {
  name: 'company',
  props: [],
  components: {
    Card,
    Graph,
    // Stats,
  },
  data() {
    return {
      firstTimeLogin: FirstTimeLogin,
      filter: [
        { label: 'Erste Ebene', value: 1 },
        { label: 'Zweite Ebene', value: 2 },
        { label: 'Dritte Ebene', value: 3 },
        { label: 'Vierte Ebene', value: 4 },
      ],
      data: {
        personal: null,
        unit: null,
        roleprofile: null,
        'performance-evaluation': null,
        evaluations: null,
        satisfaction: null,
        'development-actions': null,
        feedback: null,
      },
      loadNext: false,
      breadcrumbs: {
        pageTitle: 'Mein Unternehmen',
      },
      developmentActions: {
        title: 'Development Actions',
        resource: 'performance-evaluation/statistics/development-actions',
        searchFilters: {"category": "category"},
        columns: [
          {
            label: 'Mitarbeiter',
            field: ['personal.full_name'],
            link: 'personal.id',
            type: 'link',
            linkTo: `personal`,
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '20',
            postfix: '',
          },
          {
            label: 'Handlungsbedarf',
            field: ['title'],
            headerAlign: 'left',
            width: '15',
            type: 'text',
            postfix: '',
          },
          {
            label: 'Massnahme',
            field: ['description'],
            headerAlign: 'left',
            width: '20',
            type: 'text',
            postfix: '',
          },
          {
            label: 'Art / Typ',
            field: ['type'],
            headerAlign: 'left',
            width: '10',
            type: 'text',
            postfix: '',
          },
          {
            label: 'Umsetzung',
            field: ['realization'],
            headerAlign: 'left',
            width: '15',
            type: 'date',
            postfix: '',
          },
          {
            label: 'Budget',
            field: ['cost_company'],
            headerAlign: 'left',
            width: '10',
            type: 'text',
            postfix: '',
          },
          {
            label: 'Status',
            field: ['status'],
            headerAlign: 'left',
            width: '10',
            type: 'status',
            class: 'text-black',
            postfix: '',
          },
        ],
        rows: [],
      },
      feedbacks: {
        title: 'Development Actions',
        resource: 'organisation/feedback',
        searchFilters: {"category": "category"},
        columns: [
          {
            label: 'Feedback',
            field: ['feedback'],
            type: 'text',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '50',
            postfix: '',
          },
          {
            label: 'Kategorie',
            field: ['categorie.categorie'],
            type: 'text',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '35',
            postfix: '',
          },
          {
            label: 'Gesprächsdatum',
            field: ['created_at'],
            type: 'date',
            headerClass: 'class-in-header second-class',
            headerAlign: 'left',
            width: '15',
            postfix: '',
          },
        ],
        rows: [],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    initSatisfactionGraph(option) {
      const params = option && option.value ? `?hierarchy=${option.value}` : ''
      axios
        .get(`/performance-evaluation/statistics/satisfaction/${params}`)
        .then(response => {
          this.data['satisfaction'] = response.data.data
        })
    },
    initEvaluationGraph(option) {
      const params = option && option.value ? `?hierarchy=${option.value}` : ''
      axios
        .get(
          `/performance-evaluation/statistics/performance-evaluation/evaluations/${params}`
        )
        .then(response => {
          this.data['evaluations'] = response.data.data
        })
    },
    initGraph(year) {
      axios
        .get(`/performance-evaluation/statistics/feedback/?year=${year}`)
        .then(response => {
          this.data['feedback'] = response.data.data
        })
    },
    init() {
      // if (!this.profile.permissions.is_admin) {
      //   this.$router.push('/personal')
      // } else {
      this.$emit('breadcrumbs', this.breadcrumbs)
      setTimeout(() => {
        if (!this.profile.org.update_first_time_login) {
          this.$bus.$emit('show-modal', {
            show: true,
            component: this.firstTimeLogin,
            title: 'Konto einrichten',
            maxWidth: 'w-2xl',
            dialogType: 'wizard',
          })
        }
      }, 500)

      Promise.allSettled([
        axios.get(`/performance-evaluation/statistics/personal/`),
        axios.get(`/performance-evaluation/statistics/unit/`),
        axios.get(`/performance-evaluation/statistics/roleprofile/`),
        axios.get(`/performance-evaluation/statistics/performance-evaluation/`),
        axios.get(
          `/performance-evaluation/statistics/performance-evaluation/evaluations/`
        ),
        axios.get(`/performance-evaluation/statistics/satisfaction/`),
      ])
        .then(statuses => {
          const data = {}

          _.map(_.compact(_.map(statuses, 'value')), status => {
            data[_.last(_.compact(status.config.url.split('/')))] =
              status.data.data || status.data.results
          })
          this.data = { ...this.data, ...data }
        })
        .catch(e => {
          e
        })
      // }
    },
  },
  computed: {
    feedbackChart() {
      if (this.data.feedback) {
        const keys = _.keys(this.data.feedback.category)

        return {
          options: {
            chart: {
              type: 'donut',
              height: 900,
              toolbar: {
                show: false,
              },
            },
            toolbar: {
              show: false,
            },
            title: {
              text: this.data.feedback.amount_feedback,
              align: 'center',
              offsetY: 200,
              style: {
                fontSize: '48px',
                fontWeight: 'bold',
              },
            },
            subtitle: {
              text: 'Feedbacks',
              align: 'center',
              offsetY: 250,
            },
            labels: keys,
            legend: {
              position: 'bottom',
              horizontalAlign: 'left',
              markers: {
                width: 12,
                height: 12,
              },
              itemMargin: {
                horizontal: 15,
              },
            },
            dataLabels: {
              enabled: false,
            },
          },
          series: _.values(this.data.feedback.category),
        }
      }
      return {
        options: {
          chart: {
            type: 'donut',
            height: 100,
          },
          colors: colors,
        },
      }
    },
    evaluationChart() {
      if (this.data.evaluations && !this.data.evaluations.message) {
        const rating_scale = _.reverse(this.data.evaluations.rating_scale)
        const filteredData = _.omit(this.data.evaluations, 'rating_scale')
        const xaxis = _.keys(filteredData)

        const legends = _.uniq(
          _.flatten(_.map(filteredData, obj => _.map(obj, 'name')))
        )
        const series = []

        _.each(legends, legend => {
          series.push({
            name: legend,
            data: Array.from(_.keys(_.keys(xaxis)), x => x * 0),
          })
        })

        let index = 0

        _.map(xaxis, (xa, xindex) => {
          _.each(filteredData[xa], da => {
            const currentLegends = _.indexOf(_.map(series, 'name'), da.name)
            const oIndex = currentLegends > -1 ? currentLegends : index++

            series[oIndex].data[xindex] =
              _.get(da, 'total_mark') || _.get(da, 'total_avg_mark') || 0
          })
        })

        return {
          options: {
            chart: {
              type: 'bar',
              width: '100%',
            },
            toolbar: {
              show: false,
            },
            colors: colors,
            chartOptions: {
              chart: {
                type: 'bar',
                width: '100%',
                toolbar: {
                  show: false,
                },
              },
              toolbar: {
                show: false,
              },
              colors: colors,
              legend: {
                position: 'top',
                horizontalAlign: 'left',
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: xaxis,
              },
              yaxis: {
                tickAmount: rating_scale.length - 1,
                min: 1,
                max: rating_scale.length,
                labels: {
                  formatter: (value, seriesIndex) => {
                    return (
                      Number.isInteger(seriesIndex) &&
                      rating_scale[seriesIndex].name
                    )
                  },
                  offsetX: -25,
                },
              },
              grid: {
                padding: {
                  left: -20,
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: false,
                onDatasetHover: {
                  // highlightDataSeries: false,
                },
                y: {
                  formatter: value => value.toFixed(4),
                  title: {
                    formatter: seriesName => seriesName,
                  },
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
          },
          series,
        }
      }
      return {
        error:
          this.data && this.data.evaluations && this.data.evaluations.message,
      }
    },
    satisfactionChart() {
      if (this.data.satisfaction && !this.data.satisfaction.message) {
        const xkeys = _.dropRight(_.keys(this.data.satisfaction))
        const keys = xkeys
        const min = _.last(this.data.satisfaction.general.rating)
        const max = _.first(this.data.satisfaction.general.rating)
        const onlyData = _.omit(this.data.satisfaction, 'general')

        const names = _.uniq(
          _.flatten(
            _.map(_.omit(this.data.satisfaction, 'general'), obj =>
              _.map(obj, 'name')
            )
          )
        )

        const series = []

        _.each(names, name => {
          series.push({
            name,
            data: _.flatten(
              _.map(onlyData, satis => {
                const obj = _.first(_.filter(satis, sat => sat.name === name))

                return obj ? obj.rating_avg : 0
              })
            ),
          })
        })

        return {
          options: {
            chart: {
              type: 'bar',
              width: '100%',
              toolbar: {
                show: false,
              },
            },
            toolbar: {
              show: false,
            },
            colors: ['#D00000', ...colors],
            chartOptions: {
              chart: {
                type: 'bar',
                width: '100%',
                toolbar: {
                  show: false,
                },
              },
              toolbar: {
                show: false,
              },
              colors: ['#D00000', ...colors],
              legend: {
                position: 'top',
                horizontalAlign: 'left',
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  // endingShape: 'rounded',
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: _.uniq(keys),
              },
              yaxis: {
                min,
                max,
                labels: {
                  formatter: value => value,
                  offsetX: -20,
                },
              },
              grid: {
                padding: {
                  left: -20,
                },
              },
              tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: false,
                onDatasetHover: {
                  highlightDataSeries: false,
                },
                y: {
                  formatter: value => value.toFixed(4),
                  title: {
                    formatter: seriesName => seriesName,
                  },
                },
              },
              fill: {
                opacity: 1,
              },
            },
            dataLabels: {
              enabled: true,
            },
          },
          series,
        }
      }
      return {
        error:
          this.data && this.data.satisfaction && this.data.satisfaction.message,
      }
    },
  },
}
</script>
